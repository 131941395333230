import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Icon } from "../images/loader.svg";
import { ReactComponent as IconDark } from "../images/loader-onlight.svg";

const Loader = (props) => {

    return (
        <div className="row">
            <div className="col-12 text-center">
                {props.lightBg ? <IconDark /> : <Icon />}
            </div>
        </div>
    );

};

Loader.propTypes = {
    lightBg: PropTypes.bool
};

export default Loader;