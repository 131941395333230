import React, { useEffect, useState, Suspense, lazy } from 'react';
import styles from './App.module.scss';
import { Switch, Route, withRouter } from "react-router-dom";
import Footer from "./Footer";
import Loader from "../components/Loader";
import queryString from 'query-string';
import globals from "../globals";
import { BYPASS_CONTENT, BYPASS_MALWARE, BYPASS_PHISHING, BYPASS_VIRUS } from "../constants";

const BlockedContent = lazy(() => import('../pages/BlockedContent'));
const Banned = lazy(() => import('../pages/Banned'));
const Phishing = lazy(() => import('../pages/Phishing'));
const Malware = lazy(() => import('../pages/Malware'));
const Virus = lazy(() => import('../pages/Virus'));
const Welcome = lazy(() => import('../pages/Welcome'));
const TrialEnding = lazy(() => import('../pages/TrialEnding'));

const takeoverPages = [
    '/welcome',
    '/trial-ending'
];

const mapUrlToType = {
    '/blocked': BYPASS_CONTENT,
    '/banned': BYPASS_CONTENT,
    '/virus': BYPASS_VIRUS,
    '/phishing': BYPASS_PHISHING,
    '/malware': BYPASS_MALWARE,
    '/welcome': 'welcome',
    '/trial-ending': 'trialEnding'
};

/**
 * @param props
 * @returns {*}
 * @constructor
 *
 * TEST URL: /#/blocked?CAT=%5Balcohol_and_tobacco-lg%7Cshopping-lg%5D&RULE=%5BBlockedCategories%5D&DATETIME=%5B13/Nov/2020:16:57:23%5D&FILE=-&CODE=06e088f4c87d84456b73a83eba073d3ffbdaeea3e3f246546bf8b3237503f730a902db232a8b86f9d577b9f62db0cadddc5d020093f87e8d36f4d4c824db1d8f63e19674b69c31de07be8f63c32211bd39275e99f6151de2&LANG=eng&optcheckwfsp=DKt3wfOVuMmfkmGPQxOzets5O8I%3D&URL=https://smokemart.com.au:443&ui=UOhUw%2BINmCZWMPdPxbC%2F1PkATShhe5ZiCDVz6loLMcQRJyRH4fzhSQ5etpuigJtpWcIjES2sDguQFJa2JwyRqO7yUucMJS61CE56jBZlBC3byf86Ws3MOS60P0mmyTREykiAo6Z1CI7t2ohAmkiNtEjjJgHyC5TlwQ6ijkn4hJZo9jg0EEoBfrJdpCkm7B2dTinqEEULowj6RFtpz5Fp4eDxvdiqCvqQs%2BQFBfPpZIQS5yld9gFkfewNRMm6N%2BX1pNvV95DOWQuhIXuHh2otJA%3D%3D&hashtoken=d09532cac87343a83c14e28fd748c7b8&IP=220.233.72.155&USER=-&CLIENTID=CPE495496
 */
const App = (props) => {

    const [isInvalid, setIsInvalid] = useState(false);
    const [error, setError] = useState(null);
    const [isTrial, setIsTrial] = useState(false);
    const [serviceId, setServiceId] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [blockedUrl, setBlockedUrl] = useState('Unknown');
    const [virusName, setVirusName] = useState(null);
    const [threatCount, setThreatCount] = useState(0);

    useEffect(() => {
        const params = queryString.parse(props.location.search);
        if (params.URL) {
            setBlockedUrl(`${params.URL}`);
        }

        if (params.VIRUSNAME) {
            setVirusName(`${params.VIRUSNAME}`);
        }

        if (params.CLIENTID) {
            setClientId(params.CLIENTID);
            fetch(`https://webservices.api.exetel.com.au/v1/home-secure-bypass?${buildQuery(params)}`)
                .then(result => result.json())
                .then(result => {
                    if (result.data) {
                        setIsTrial(result.data.trial);
                        setServiceId(result.data.broadbandServiceId);
                        setThreatCount(result.data.totalThreatCount);
                    } else {
                        globals.handleError(`Invalid data retrieved from API endpoint /v1/home-secure-bypass. ClientId: ${params.CLIENTID}`, result);
                        setIsInvalid(true);
                        if (result.errors) {
                            setError(result.errors);
                        }
                    }
                })
                .catch(error => {
                    globals.handleError(`Error pulling data from API endpoint /v1/home-secure-bypass.`, {clientId: params.CLIENTID, error: error});
                    setIsInvalid(true);
                });

        } else {
            setIsInvalid(true);
        }
    }, [props.location.search]);

    const buildQuery = params => {
        let query = '';
        let data = {
            clientId: params.CLIENTID,
            cat: params.CAT,
            rule: params.RULE,
            datetime: params.DATETIME,
            file: params.FILE,
            ip: params.IP,
            url: params.URL,
            bypassType: bypassType()
        };

        Object.keys(data).forEach(key => {
            query += `&${key}=${data[key]}`;
        });

        return query.substring(1);
    };

    const bypassType = () => {
        if (mapUrlToType[props.location.pathname]) {
            return mapUrlToType[props.location.pathname];
        }

        globals.handleError('Unhandled pathname in mapUrlToType in App.js', {pathname: props.location.pathname});

        return 'Unknown';
    };

    const isTakeoverPage = () => {
        return takeoverPages.includes(props.location.pathname);
    };

    const Invalid = (
        <div className="text-center">
            <h1 className="mb-3">Invalid request</h1>
            {error ? <p className="bold">{error}</p> : ''}
            <p>The request is missing some important information, please try reloading the page to see if the issue persists.</p>
        </div>
    );

    return (
        <div className={isTakeoverPage() ? styles.containerTakeover : styles.container}>
            <div className={isTakeoverPage() ? styles.innerContainerTakeover : styles.innerContainer}>
                {isInvalid ? Invalid :
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            <Route path="/blocked">
                                <BlockedContent
                                    isTrial={isTrial}
                                    serviceId={serviceId}
                                    blockedUrl={blockedUrl}
                                    clientId={clientId}
                                />
                            </Route>
                            <Route path="/banned">
                                <Banned />
                            </Route>
                            <Route path="/phishing">
                                <Phishing
                                    isTrial={isTrial}
                                    serviceId={serviceId}
                                    blockedUrl={blockedUrl}
                                    clientId={clientId}
                                />
                            </Route>
                            <Route path="/malware">
                                <Malware
                                    isTrial={isTrial}
                                    serviceId={serviceId}
                                    blockedUrl={blockedUrl}
                                    virusName={virusName}
                                    clientId={clientId}
                                />
                            </Route>
                            <Route path="/virus">
                                <Virus
                                    isTrial={isTrial}
                                    serviceId={serviceId}
                                    blockedUrl={blockedUrl}
                                    virusName={virusName}
                                    clientId={clientId}
                                />
                            </Route>
                            <Route path="/welcome">
                                <Welcome serviceId={serviceId} isTrial={isTrial} url={blockedUrl} />
                            </Route>
                            <Route path="/trial-ending">
                                <TrialEnding serviceId={serviceId} isTrial={isTrial} url={blockedUrl} threatCount={threatCount} />
                            </Route>
                        </Switch>
                    </Suspense>
                }
            </div>
            <Footer isTrial={isTrial} serviceid={serviceId} isTakeoverPage={isTakeoverPage()} />
        </div>
    );
}

export default withRouter(App);
