import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import 'isomorphic-fetch';
import App from './layout/App';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from "react-router-dom";
import ErrorBoundary from "./layout/ErrorBoundary";
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://987a2aa4c85340ecb824394af55ea422@sentry2.intdev.exetel.com.au/29',
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
