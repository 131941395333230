import React from "react";
import globals from "../globals";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        globals.handleError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="text-center">
                    <h1>Oops. Something went wrong.</h1>
                    <p>Something is not right here... You can try reloading the page.</p>
                    <p>We have been notified of this issue and will look into it shortly.</p>
                </div>
            );
        }

        return this.props.children;
    }

}

export default ErrorBoundary;