import * as Sentry from '@sentry/browser';
import axios from "axios";

export default {

    handleError(error, errorInfo) {
        console.error(error, errorInfo);
        return new Promise(resolve => {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                const eventId = Sentry.captureMessage(error);
                resolve(eventId);
            });
        });
    },

    postBypass(data) {
        return new Promise((resolve, reject) => {
            axios.post(`https://webservices.api.exetel.com.au/v1/home-secure-bypass`, data)
                .then(response => {
                    if (response.status === 200) {
                        resolve();
                    } else {
                        this.handleError(`Non-200 response coming from POST request /v1/home-secure/bypass`, {response: response, data: data});
                        reject();
                    }
                })
                .catch(error => {
                    this.handleError(`Invalid response coming from POST request /v1/home-secure/bypass`, {error: error, data: data});
                    reject();
                });
        });
    },

    noWidows(text) {
        if (text) {
            return text.replace(/\s(?=[^\s]*$)/g, "&nbsp;");
        }
    },

    pushEvent(name, values = {}) {
        let data = {};

        if (Object.keys(values).length > 0) {
            data = {...values, event: name}
        } else {
            data = {event: name};
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }

}