import React from "react";
import PropTypes from "prop-types";
import styles from "./Footer.module.scss";

const Footer = (props) => {

    const cancelUrl = `https://my.exetel.com.au/my-services/home-secure/${props.serviceId}/manage-subscription`;

    return (
        <div className={`${styles.footer} ${props.isTakeoverPage && styles.takeover}`}>
            <a href="https://exetel.com.au/broadband/nbn/home-secure" target="_blank" rel="noopener noreferrer" className="gtm-hs-blockpage-learnmore">Learn more about Home Secure</a>{props.isTrial && <span> | <a href={cancelUrl} target="_blank" rel="noopener noreferrer" className="gtm-hs-blockpage-cancelsubscription">Cancel my trial</a></span>}
        </div>
    );

};

Footer.propTypes = {
    isTrial: PropTypes.bool.isRequired,
    serviceId: PropTypes.number,
    isTakeoverPage: PropTypes.bool
};

export default Footer;